import { initHamburgerMenu } from "./hamburger-menu.js";
import { initDropdownMenu } from "./dropdown-menu.js";
import { initToggleMenu } from "./toggle-menu.js";
import { megaMenuOverlay } from "./utilities.js";

/*==========================================
 Initializers
===========================================*/

//Hamburger
initHamburgerMenu();

// DropdownMenu
initDropdownMenu();

//Mobile submenu
initToggleMenu();

/*==========================================
 Mega Menu Overlay
===========================================*/

export const resizeMegaMenuOverlay = element => {
    setTimeout(() => {
        megaMenuOverlay.classList.add("v-open");
        megaMenuOverlay.style.height = `${element.clientHeight}px`;
    }, 100);
};

export const resetMegaMenuOverlay = () => {
    megaMenuOverlay.classList.remove("v-open");
    megaMenuOverlay.style.height = 0;
};
